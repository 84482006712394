import { flex } from '../../../components/AvThemeProvider';
import { DescriptorType } from '../../../context/context.type';
import { ellipsis } from '../../../utils/Utils';

export const DragItem = {
  Parsed: 1,
  Entity: 2,
};

export const MapFilterType = {
  Mapped: 1,
  UnMapped: 2,
};

export const MappingType = {
  Script: 'SCRIPT',
  Literal: 'VALUE',
  Path: 'PATH',
};

export type MappingTreeNode = {
  label: string;
  isMissing: boolean;
  children: MappingTreeNode[];
  path?: string;
  root?: string;
};

export const FieldType = {
  Number: 'DOUBLE',
  Text: 'STRING',
  Date: 'DATE',
  IP: 'IP',
  FIX: 'FIX',
  Boolean: 'BOOL',
  INT: 'INT',
  UINT32: 'UINT32',
  Message: 'MESSAGE',
  ENUM: 'ENUM',
};

export const FieldTypeEnum = {
  [FieldType.Text]: DescriptorType.TYPE_STRING,
  [FieldType.Number]: DescriptorType.TYPE_DOUBLE,
  [FieldType.Boolean]: DescriptorType.TYPE_BOOL,
  [FieldType.INT]: DescriptorType.TYPE_INT32,
  [FieldType.UINT32]: DescriptorType.TYPE_UINT32,
  [FieldType.ENUM]: DescriptorType.TYPE_ENUM,
  [FieldType.Date]: 'DATE',
  [FieldType.IP]: 'IP',
  [FieldType.Message]: 'MESSAGE',
  [DescriptorType.TYPE_STRING]: FieldType.Text,
  [DescriptorType.TYPE_DOUBLE]: FieldType.Number,
  [DescriptorType.TYPE_BOOL]: FieldType.Boolean,
  [DescriptorType.TYPE_INT32]: FieldType.INT,
  [DescriptorType.TYPE_UINT32]: FieldType.UINT32,
  [DescriptorType.TYPE_ENUM]: FieldType.ENUM,
  DATE: FieldType.Date,
  IP: FieldType.IP,
  FIX: FieldType.FIX,
  MESSAGE: FieldType.Message,
};
export enum SchemaTypes {
  STRING = 'string',
  BOOL = 'bool',
  DATE = 'date',
  IP = 'IP',
  FIX = 'FIX',
  NUMBER = 'number',
}
export const typeLabelMap = {
  [FieldTypeEnum[FieldType.Text]]: SchemaTypes.STRING,
  TYPE_STRING: SchemaTypes.STRING,
  [FieldTypeEnum[FieldType.Boolean]]: SchemaTypes.BOOL,
  TYPE_BOOL: SchemaTypes.BOOL,
  [FieldTypeEnum[FieldType.Date]]: SchemaTypes.DATE,
  DATE: SchemaTypes.DATE,
  [FieldTypeEnum[FieldType.IP]]: SchemaTypes.IP,
  IP: SchemaTypes.IP,
  [FieldTypeEnum[FieldType.FIX]]: SchemaTypes.FIX,
  FIX: SchemaTypes.FIX,
  [FieldTypeEnum[FieldType.Number]]: SchemaTypes.NUMBER,
  TYPE_DOUBLE: SchemaTypes.NUMBER,
  [FieldTypeEnum[FieldType.INT]]: SchemaTypes.NUMBER,
  TYPE_INT32: SchemaTypes.NUMBER,
  [FieldTypeEnum[FieldType.UINT32]]: SchemaTypes.NUMBER,
  TYPE_UINT32: SchemaTypes.NUMBER,
};

export const ModelSchemaType = {
  BUILTIN_STATIC: 'BUILTIN_STATIC',
  BUILTIN_DYNAMIC: 'BUILTIN_DYNAMIC',
  CUSTOM_DYNAMIC: 'CUSTOM_DYNAMIC',
};

export const itemStyle = (isSmall?) => ({
  ...flex.itemsCenter,
  gap: 1,
  flexShrink: 0,
  width: isSmall ? 266 : 282,
  height: isSmall ? 32 : 40,
  fontSize: isSmall ? 13 : 14,
  background: theme => theme.palette.white.main,
  px: 2,
  borderRadius: '2px',
  svg: {
    color: theme => theme.palette.colors.primary[500],
    width: isSmall ? 18 : undefined,
    height: isSmall ? 18 : undefined,
  },
  span: ellipsis,
  transition: theme => theme.transitions.create(['box-shadow']),
  ':hover': {
    boxShadow: '0px 15px 20px rgba(0, 0, 0, 0.08)',
  },
});

export const buildMappingKey = item =>
  `${item.entityName}${item.modelSchemaType}${item.modelFieldName}${item.mappingValue}${!item.deleted ? 'not-deleted' : 'deleted'}`;
export const GENERAL_ENTITY = 'General';
