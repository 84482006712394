import { useMemo } from 'react';
import {
  APP_PATHS,
  AppPathsValue,
  INTERNAL_ROLES,
  PathFeatureFlag,
  Permission,
  PermissionCategoriesNames,
  PermissionEntitiesNames,
} from '../types';
import { appToPermissionCategory, fullPermissionsToResource, permissionMappingPerRoute } from '../utils/permissions.utils';
import { AvPermissionsType, PagePathsValue } from './AvContext.types';
import { permissionArrayToTree } from './utils';

export const usePermissions = ({ featureFlags, user }): AvPermissionsType => {
  const allowedPermissions = useMemo(() => permissionArrayToTree(user.permissions.split(' ')), [user.permissions]);
  return useMemo(
    () => ({
      hasAllowedPermission: hasAllowedPermission({ allowedPermissions, user, featureFlags }),
      hasOnlyPermission: hasOnlyPermission({ allowedPermissions }),
      hasAllowedPermissionToResource: hasAllowedPermissionToResource({ allowedPermissions }),
      allowedPermissionsToResource: allowedPermissionsToResource({ allowedPermissions }),
      allowedPermissionsToPath: allowedPermissionsToPath({ allowedPermissions }),
      hasAllowedEditResourcePermission: hasAllowedEditResourcePermission({ allowedPermissions }),
      isInternalRole: isInternalRole({ user }),
      isAvalorAdmin: isAvalorAdmin({ user }),
      allowedPermissions,
    }),
    [allowedPermissions, featureFlags, user]
  );
};

const isAllowedPathByFeatureFlag = featureFlags => (path: PagePathsValue) => !PathFeatureFlag[path] || featureFlags[PathFeatureFlag[path]];

const hasPermission = (category, allowedPermissions, permissionEntity, permission: Permission) =>
  category ? allowedPermissions[category]?.[permissionEntity]?.[permission] : allowedPermissions[permissionEntity]?.[permission];

export const hasAllowedPermission =
  ({ allowedPermissions, user, featureFlags }) =>
  ({ app, path, permission = Permission.READ }: { app?: AppPathsValue; path: PagePathsValue; permission?: Permission }) => {
    const { category, permissionEntity, requiredRole, relevantFeatureFlag } =
      permissionMappingPerRoute(appToPermissionCategory[app!])[path] || {};
    return isAllowedPathByFeatureFlag(featureFlags)(path) && requiredRole
      ? user.roleName === requiredRole
      : (relevantFeatureFlag && featureFlags[relevantFeatureFlag]) ||
          hasPermission(category, allowedPermissions, permissionEntity, permission) ||
          false;
  };

export const useHasAppInAccount =
  ({ accountData }) =>
  ({ appPath }) =>
    accountData.apps.map(app => APP_PATHS[app.name]).includes(appPath);

export const hasOnlyPermission =
  ({ allowedPermissions }) =>
  ({ app, path, permission = Permission.READ }: { app?: AppPathsValue; path: PagePathsValue; permission?: Permission }) => {
    const { category, permissionEntity } = permissionMappingPerRoute(appToPermissionCategory[app!])[path] || {};
    const allPermissions = Object.keys(Permission) as Permission[];
    return allPermissions.every(p => {
      const hasCurrentPermission = hasPermission(category, allowedPermissions, permissionEntity, p);
      return p === permission ? hasCurrentPermission : !hasCurrentPermission;
    });
  };

export const hasAllowedPermissionToResource =
  ({ allowedPermissions }) =>
  ({
    resource,
    category,
    permission = Permission.READ,
  }: {
    resource: PermissionEntitiesNames;
    category?: PermissionCategoriesNames;
    permission?: Permission;
  }) =>
    !!(category ? allowedPermissions[category] : allowedPermissions)?.[resource]?.[permission];

export const allowedPermissionsToResource =
  ({ allowedPermissions }) =>
  ({ resource, category }: { resource: PermissionEntitiesNames; category: PermissionCategoriesNames }) =>
    allowedPermissions[category]?.[resource];

export const hasAllowedEditResourcePermission =
  ({ allowedPermissions }) =>
  ({ app, path, isNew = false }: { app?: AppPathsValue; path: PagePathsValue; isNew?: boolean }) => {
    const { category, permissionEntity } = permissionMappingPerRoute(appToPermissionCategory[app!])[path];
    const permission = isNew ? Permission.CREATE : Permission.UPDATE;
    return !!(category
      ? allowedPermissions[category]?.[permissionEntity]?.[permission]
      : allowedPermissions[permissionEntity]?.[permission]);
  };

export const allowedPermissionsToPath =
  ({ allowedPermissions }: { allowedPermissions: Record<string, any> }) =>
  ({ app, path }: { app?: AppPathsValue; path: PagePathsValue }) => {
    const { category, permissionEntity } = permissionMappingPerRoute(appToPermissionCategory[app!])[path];
    return {
      ...fullPermissionsToResource(false),
      ...(category ? allowedPermissions[category]?.[permissionEntity] : allowedPermissions[permissionEntity]),
    };
  };

export const isInternalRole = ({ user }) => user.roleName && !!INTERNAL_ROLES[user.roleName];
export const isAvalorAdmin = ({ user }) => user.roleName && INTERNAL_ROLES[user.roleName] === INTERNAL_ROLES.RND_ADMIN;
