import { PAGE_PATHS } from './routes.types';

export const INTERNAL_ROLES = {
  RND_ADMIN: 'RND_ADMIN',
  SYS_ADMIN: 'SYS_ADMIN',
};

// TODO: cleanup after permissions is fully migrated
export const ROLES = {
  RND_ADMIN: 7,
  ADMIN: 5,
  EDITOR: 4,
  VIEWER: 3,
  SIEM_USER: 2,
  PRE_POV: 1,
  SYS_ADMIN: 18,
};

export const OLD_PREDEFINED_ROLE_NAMES = {
  EDITOR: 'EDITOR',
  VIEWER: 'VIEWER',
  SIEM_USER: 'SIEM_USER',
};

export const NEW_PREDEFINED_ROLE_NAMES = { DEFAULT: 'DEFAULT', RND_ADMIN: 'RND_ADMIN', ADMIN: 'ADMIN' };
export const PREDEFINED_ROLE_NAMES = { ...OLD_PREDEFINED_ROLE_NAMES, ...NEW_PREDEFINED_ROLE_NAMES };

export const pathsByRole = {
  // TODO: when relevant update this object from the server response
  [ROLES.RND_ADMIN]: {
    [PAGE_PATHS.CONTENT_PERMISSIONS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.USER_MANAGEMENT]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.ALIASES]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.ALL_USERS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.ALL_ACCOUNTS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.SOURCES]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.SOURCES_MAPPING]: { READ: true, CREATE: true, UPDATE: true },
    [PAGE_PATHS.TARGETS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.RULES]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.ALERTS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.GLOBAL_SCRIPTS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.TICKETS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.INCIDENTS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.ASSETS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.FINDINGS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.WORKFLOWS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.SETTINGS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.MODEL_MANAGEMENT]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.DATA_UNIFICATION_FIELDS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.DATA_UNIFICATION_ENTITIES]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.DATA_UNIFICATION]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.QUERY]: { READ: true, UPDATE: true },
    [PAGE_PATHS.ENTITIES]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.LOOKUP_TABLES]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.ROI]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.REMEDIATION]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.ASSET_COVERAGE]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.RISK]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.OVERVIEW]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.PIVOT]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.EDIT_MY_ACCOUNT_MANAGEMENT]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.EDIT_MY_USER]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.TEST_QUERY]: { READ: true },
    [PAGE_PATHS.REPORTS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.BACKOFFICE_ACTIONS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.ROLES_OVERVIEW]: { READ: true },
    [PAGE_PATHS.CUSTOM_DASHBOARDS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.LOGS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.RULE_SET]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.SCORE]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.SEVERITY_SLA]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.TICKETS_STATUSES]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.UI_CONFIG]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.PERMISSIONS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.ACCOUNT_USAGE]: { READ: true },
    [PAGE_PATHS.ROLES]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.MEASUREMENTS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.POLICY_VIOLATION]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
  },
  [ROLES.SYS_ADMIN]: {
    [PAGE_PATHS.CONTENT_PERMISSIONS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.USER_MANAGEMENT]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.ALL_ACCOUNTS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.SOURCES]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.SOURCES_MAPPING]: { READ: true, CREATE: true, UPDATE: true },
    [PAGE_PATHS.TARGETS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.RULES]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.ALERTS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.GLOBAL_SCRIPTS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.TICKETS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.INCIDENTS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.ASSETS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.FINDINGS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.WORKFLOWS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.SETTINGS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.MODEL_MANAGEMENT]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.DATA_UNIFICATION]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.QUERY]: { READ: true, UPDATE: true },
    [PAGE_PATHS.ENTITIES]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.LOOKUP_TABLES]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.ROI]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.REMEDIATION]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.ASSET_COVERAGE]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.OVERVIEW]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.PIVOT]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.EDIT_MY_ACCOUNT_MANAGEMENT]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.EDIT_MY_USER]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.TEST_QUERY]: { READ: true },
    [PAGE_PATHS.REPORTS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.ROLES_OVERVIEW]: { READ: true },
    [PAGE_PATHS.CUSTOM_DASHBOARDS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.LOGS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.RULE_SET]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.SCORE]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.SEVERITY_SLA]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.TICKETS_STATUSES]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.UI_CONFIG]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.PERMISSIONS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.ACCOUNT_USAGE]: { READ: true },
    [PAGE_PATHS.ROLES]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.MEASUREMENTS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.POLICY_VIOLATION]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
  },
  [ROLES.ADMIN]: {
    [PAGE_PATHS.CONTENT_PERMISSIONS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.SETTINGS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.USER_MANAGEMENT]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.SOURCES]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.SOURCES_MAPPING]: { READ: true, CREATE: true, UPDATE: true },
    [PAGE_PATHS.TARGETS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.LOGS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.MODEL_MANAGEMENT]: { READ: true },
    [PAGE_PATHS.ENTITIES]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.TICKETS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.INCIDENTS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.ASSETS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.FINDINGS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.REMEDIATION]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.ASSET_COVERAGE]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.RISK]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.OVERVIEW]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.PIVOT]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.EDIT_MY_ACCOUNT_MANAGEMENT]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.EDIT_MY_USER]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.REPORTS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.RULES]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.GLOBAL_SCRIPTS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.LOOKUP_TABLES]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.BACKOFFICE_ACTIONS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.CUSTOM_DASHBOARDS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.ACCOUNT_USAGE]: { READ: true },
    [PAGE_PATHS.ALERTS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.RULE_SET]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.SCORE]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.SEVERITY_SLA]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.MEASUREMENTS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
  },
  [ROLES.EDITOR]: {
    [PAGE_PATHS.RULES]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.LOGS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.GLOBAL_SCRIPTS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.TICKETS]: { READ: true },
    [PAGE_PATHS.INCIDENTS]: { READ: true },
    [PAGE_PATHS.ASSETS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.FINDINGS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.ENTITIES]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.MODEL_MANAGEMENT]: { READ: true },
    [PAGE_PATHS.SOURCES]: { READ: true, CREATE: true, UPDATE: true },
    [PAGE_PATHS.SOURCES_MAPPING]: { READ: true },
    [PAGE_PATHS.LOOKUP_TABLES]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.REMEDIATION]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.ASSET_COVERAGE]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.RISK]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.OVERVIEW]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.PIVOT]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.TEST_QUERY]: { READ: true },
    [PAGE_PATHS.REPORTS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.BACKOFFICE_ACTIONS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.CUSTOM_DASHBOARDS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.TARGETS]: { READ: true },
  },
  [ROLES.VIEWER]: {
    [PAGE_PATHS.TICKETS]: { READ: true },
    [PAGE_PATHS.INCIDENTS]: { READ: true },
    [PAGE_PATHS.ASSETS]: { READ: true },
    [PAGE_PATHS.FINDINGS]: { READ: true },
    [PAGE_PATHS.LOGS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.MODEL_MANAGEMENT]: { READ: true },
    [PAGE_PATHS.SOURCES]: { READ: true },
    [PAGE_PATHS.SOURCES_MAPPING]: { READ: true },
    [PAGE_PATHS.ENTITIES]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.REMEDIATION]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.ASSET_COVERAGE]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.RISK]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.PIVOT]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.REPORTS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.BACKOFFICE_ACTIONS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.OVERVIEW]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.CUSTOM_DASHBOARDS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
  },
  [ROLES.SIEM_USER]: {
    [PAGE_PATHS.SOURCES]: { READ: true, CREATE: true, UPDATE: true },
    [PAGE_PATHS.SOURCES_MAPPING]: { READ: true },
    [PAGE_PATHS.RULES]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.LOGS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.ASSETS]: { READ: true },
    [PAGE_PATHS.FINDINGS]: { READ: true },
    [PAGE_PATHS.GLOBAL_SCRIPTS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.TICKETS]: { READ: true },
    [PAGE_PATHS.INCIDENTS]: { READ: true },
    [PAGE_PATHS.ENTITIES]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.PIVOT]: { READ: true },
    [PAGE_PATHS.LOOKUP_TABLES]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
    [PAGE_PATHS.TEST_QUERY]: { READ: true },
    [PAGE_PATHS.BACKOFFICE_ACTIONS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
  },
  [ROLES.PRE_POV]: {
    [PAGE_PATHS.SOURCES]: { READ: true, CREATE: true, UPDATE: true },
    [PAGE_PATHS.SOURCES_MAPPING]: { READ: true },
    [PAGE_PATHS.BACKOFFICE_ACTIONS]: { READ: true, CREATE: true, DELETE: true, UPDATE: true },
  },
};

export const FeatureFlags = {
  ShowAssetsDashboardOverview: 'assets-overview-dashboard',
  RefetchTicketOnFocus: 'refetch-ticket-on-window-focus',
  ForceFixTextInTicketTab: 'force-fix-text-in-ticket-tab',
  ManualUpdateTicketIntegration: 'manual-update-ticket-integration',
  KeepSessionFilters: 'keep-session-filters',
  createTicketFromAlerts: 'create-ticket-from-alerts',
  ShowRoiDashboard: 'show-roi-dashboard',
  ScriptPerFieldPage: 'script-per-field-page',
  FixTabNew: 'fix-tab-support-text-and-version',
  CollapseHiddenTicketFlachColumns: 'collapse-hidden-ticket-flach-column',
  SortTicketFlachTablesByState: 'sort-ticket-flach-tables-by-remediation',
  ShowServerErrorInToast: 'show-server-error-in-toast',
  ShowDataLakeQueries: 'show-data-lake-queries',
  restoreAllTypesDefaultOrder: 'restore-all-types-default-order',
  SPFValuesFallback: 'script-per-field-values-fallback',
  ShowGroupingSettings: 'show-grouping-settings',
  TriggerIntegrationButton: 'trigger-integration-button',
  ShowMetricsFromDbRiskRemDash: 'show-metrics-form-db-risk-rem-dashboards',
  disableCacheQueryClient: 'disable-cache-query-client',
  QuerySQLDebounce: 'query-sql-debounce',
  AssetMergingTab: 'asset-merging-tab',
  ShowDataLakeMonths: 'ShowDataLakeMonths',
  deleteSaml: 'delete-saml',
  AllowExportDashboard: 'allow-export-dashboard',
  FindingView: 'finding-view',
  ReportsHistorical: 'enable-reports-historical',
  DashboardGlobalFilters: 'dashboard-global-filters',
  NewLogSearch: 'new-log-search',
  ShowOldLogs: 'show-old-logs',
  CustomRolesPage: 'custom-roles-page',
  AllowExportFixTab: 'allow-export-fixes-tab',
  FieldVisibility: 'field-visibility',
  MeasurementsPage: 'measurements-page',
  FixTableDrillDown: 'fix-tab-drilldown-to-findings',
  DataUnificationRules: `data-unification`,
  EnableUnificationCEL: `enable-unification-cel`,
  RemoveBackslashEscapingFromWhere: 'remove-backslash-escaping-from-where',
  IdleTimoutForLogout: `idle-timeout-for-logout`,
  BatchAggregation: 'batch-aggregation',
  ShowDrillDown: 'show-drill-down',
  ClickthroughCustomDashboards: 'clickthrough-custom-dashboards',
  NewAppManagementQueries: 'new-app-management-queries',
  HistoricalAnalytics: 'historical-analytics',
  ShowOrgEntityPane: 'show-org-entity-pane',
  PivotFixes: 'pivot-fixes',
  InvestigateEntity: 'investigate-entity',
  VisualExplorerRealRelations: 'visual-explorer-real-relations',
  AllowEmptyAssignmentOnUnification: 'support-empty-assignment-unification-rules',
  accountUsagePage: 'account-usage-page',
  forcePushdownUnification: 'force-pushdown-unification-rules',
  FindingsViewManualGrouping: 'findings-view-manual-grouping',
  KeepSlaWithoutFormatting: 'keep-sla-without-formatting',
  autoMapping: 'auto-mapping',
  AgingRules: 'aging-rules',
  NewDynamicRange: 'new-dynamic-range',
  NewOutegrationScreen: 'new-outegration-screen',
  QueryObjectEditor: 'widget-query-object-editor',
  GroupingRulesV2: 'grouping-rules-v2',
  NewRolesForUserMgmPage: 'new-roles-for-user-mgm',
  ShowFactorRulesPage: 'show-factor-rules-page',
  ShowAliasPage: 'show-alias-page',
  ShowMeasurementsPage: 'show-measurements-page',
  newOutegrationMappingScreenPreview: 'new-outegration-mapping-screen',
  CelDateFilters: 'cel-date-filters',
  NewLogsScreen: 'new-logs-screen',
  EnhancedClustering: 'enhanced-clustering',
  FormatNull: 'format-null',
  RiskMass: 'risk-mass',
  MultiBreakdown: 'multi-breakdown',
};

export const PathFeatureFlag = {
  [PAGE_PATHS.MODEL_MANAGEMENT]: FeatureFlags.ScriptPerFieldPage,
  [PAGE_PATHS.ROI]: FeatureFlags.ShowRoiDashboard,
  [PAGE_PATHS.FINDINGS]: FeatureFlags.FindingView,
  [PAGE_PATHS.LOGS]: FeatureFlags.NewLogSearch,
  [PAGE_PATHS.RULE_SET]: FeatureFlags.ShowGroupingSettings,
  [PAGE_PATHS.ROLES]: FeatureFlags.CustomRolesPage,
  [PAGE_PATHS.MEASUREMENTS]: FeatureFlags.MeasurementsPage,
  [PAGE_PATHS.DATA_UNIFICATION_FIELDS]: FeatureFlags.DataUnificationRules,
  [PAGE_PATHS.DATA_UNIFICATION_ENTITIES]: FeatureFlags.EnhancedClustering,
  [PAGE_PATHS.DATA_UNIFICATION]: FeatureFlags.DataUnificationRules,
  [PAGE_PATHS.ACCOUNT_USAGE]: FeatureFlags.accountUsagePage,
  [PAGE_PATHS.FACTOR_RULES]: FeatureFlags.ShowFactorRulesPage,
};

export enum Permission {
  READ = 'READ',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export enum PermissionEntitiesNames {
  // Platform
  MODEL = 'MODEL',
  SOURCE = 'SOURCE',
  SOURCE_MAPPING = 'SOURCE_MAPPING',
  WORKFLOW_SCRIPT = 'WORKFLOW_SCRIPT',
  USER = 'USER',
  ACCOUNT = 'ACCOUNT',
  VIEWS = 'VIEWS',
  ROLE = 'ROLE',
  INTEGRATION = 'INTEGRATION',
  ALIAS = 'ALIAS',
  ZCONFIG = 'ZCONFIG',

  // Explore
  SEARCH = 'SEARCH',
  REPORT = 'REPORT',

  // Multiple Apps
  ANALYTICS = 'ANALYTICS',

  // Entity
  OPT = 'OPT',
  SETTINGS = 'SETTINGS',
  INT = 'INT',
  QUERY_RUNNER = 'QUERY_RUNNER',

  // Detection App
  RULE = 'RULE',
  GLOBAL_SCRIPT = 'GLOBAL_SCRIPT',
  LOOKUP_TABLE = 'LOOKUP_TABLE',

  // Risk 360 App
  EXE_RULE = 'EXE_RULE',
  BUILTIN_EXE_RULE = 'BUILTIN_EXE_RULE',

  // Assets App
  POLICY_VIOLATION = 'POLICY_VIOLATION',

  // Backoffice
  BACKOFFICE = 'BACKOFFICE',
}

export enum PermissionAppName {
  PLATFORM = 'PLATFORM',
  EXPLORE = 'EXPLORE',
  ENTITY = 'ENTITY',
  VULNERABILITIES = 'VULNERABILITIES',
  DETECTIONS = 'DETECTIONS',
  INCIDENTS = 'INCIDENTS',
  ASSETS = 'ASSETS',
  RISK360 = 'RISK360',
}

export enum PermissionModelEntityName {
  TICKET = 'TICKET',
  FINDING = 'FINDING',
  ASSET = 'ASSET',
  INCIDENT = 'INCIDENT',
  ALERT = 'ALERT',
  FACTOR = 'FACTOR',
}

export type PermissionCategoriesNames = PermissionAppName | PermissionModelEntityName;

export type PermissionForRoute =
  | {
      category?: PermissionCategoriesNames;
      permissionEntity: PermissionEntitiesNames;
    }
  | { requiredRole: keyof typeof PREDEFINED_ROLE_NAMES };
