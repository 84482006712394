import React, { useMemo } from 'react';
import { Box, ToggleButton, ToggleButtonGroup, Typography, useTheme } from '@mui/material';
import { keepPreviousData } from '@tanstack/react-query';
import { endOfDay } from 'date-fns';
import RGL, { WidthProvider } from 'react-grid-layout';
import { useLocation } from 'react-router-dom';
import { flex } from '../../components/AvThemeProvider';
import AvDateRangePicker from '../../components/DatePicker/AvDateRangePicker';
import { RelativeDatePeriod, RelativeDateType } from '../../components/DatePicker/AvDateRangePicker.constants';
import { DatePickerStartAdornment } from '../../components/DatePicker/utils';
import ExportButton from '../../components/ExportButton';
import { getDateFromFilter, getStringConditionFromDatePicker } from '../../components/filters/Utils';
import ActiveTicketsProgress from '../../components/Widgets/ActiveTicketsProgress';
import KeyMetricsOverTime from '../../components/Widgets/KeyMetricsOverTime';
import LineWidget from '../../components/Widgets/LineWidget';
import OverTimeTable, { OverTimeTableHeader } from '../../components/Widgets/OverTimeTable';
import SeverityStatusBars from '../../components/Widgets/SeverityStatusBars';
import Widget from '../../components/Widgets/Widget';
import { useAvContext } from '../../context/AvContextProvider';
import { SearchContextKeys } from '../../context/SearchContext.types';
import { useCustomSearchParams } from '../../hooks/UseCustomSearchParams';
import useQuerySql from '../../hooks/useQuerySql';
import { FeatureFlags } from '../../types';
import { DateCondition } from '../../types/filter.types';
import { ScreenType } from '../../types/savedViews.types';
import { useWidgetData } from '../../utils/dashboardDataUtils';
import { DateFormats, DateGap } from '../../utils/date.utils';
import { entityViewConfig } from '../../utils/entityViewConfig';
import { getIfValidStringifiedObject, isNullOrUndefined } from '../../utils/Utils';
import { useExportDashboard } from '../CustomDashboards/hooks';
import { DashboardType } from '../CustomDashboards/types';
import { useSavedViews } from '../Entities/hooks';
import { FileFormat } from '../Reports/types';
import { useBuildWhereClause } from '../Tickets/hooks';
import { explodeFields } from '../Tickets/ticket.types';
import TicketFilters from '../Tickets/TicketFilters';
import { getDefaultStartDate, useRemediationMeasurementOptions } from './Utils';
import { ReactComponent as HistoryData } from '../../assets/History.svg';

const ReactGridLayout = WidthProvider(RGL);

const metricLimit = 8;

type TransformedFiltersType = {
  filters: object;
  props: {
    keyMetrics: string[];
    analyze: { sort: never[]; metrics: string[]; rowDim: string; colDim: string; page: string };
    dateObject?: { value: { to: string; from: string }; preset: { count: number; period: RelativeDatePeriod; type: RelativeDateType } };
    startDate?: string[];
    dateGap: DateGap[];
  };
};

function DashboardRemediationPage() {
  const theme = useTheme();
  const { UIConfig, featureFlags } = useAvContext();
  const location = useLocation();
  const showExportDashboard = featureFlags[FeatureFlags.AllowExportDashboard];
  const owner = 'ticket.assignee_id';
  const defaultFilters = { [owner]: [], dateDimPeriod: ['3'] };
  const [filters, setFilters] = useCustomSearchParams({
    defaultFilter: defaultFilters,
    shouldBeArray: () => true,
    contextSearchKey: SearchContextKeys.RemediationDashboard,
  });
  const isWidgetPropsKey = key =>
    ['analyze', 'keyMetrics', 'dateGap', featureFlags[FeatureFlags.NewDynamicRange] ? 'dateObject' : 'startDate', 'dateDimPeriod'].includes(
      key
    );
  const { exportDashboard, isLoading: isExportingDashboard } = useExportDashboard({
    dashboardExportDto: {
      dashboardType: DashboardType.REMEDIATION_DASHBOARD,
      fileFormat: FileFormat.PDF_FORMAT,
      searchParams: location.search,
    },
  });

  const transformedFilters: TransformedFiltersType = useMemo(
    () =>
      Object.keys(filters).reduce(
        (acc, key) => {
          if (!isWidgetPropsKey(key)) {
            acc.filters = { ...acc.filters, [key]: filters[key] };
            return acc;
          }
          const value = getIfValidStringifiedObject(filters[key]) || filters[key];
          if (key === 'analyze' && !Array.isArray(value.metrics)) {
            value.metrics = [value.metrics];
          }
          acc.props = { ...acc.props, [key]: value };
          return acc;
        },
        {
          filters: {},
          props: {
            dateGap: [DateGap.Month],
            ...(featureFlags[FeatureFlags.NewDynamicRange]
              ? {
                  dateObject: {
                    value: {
                      to: getDefaultStartDate(),
                      from: endOfDay(new Date()).toISOString(),
                    },
                    preset: {
                      count: 3,
                      period: RelativeDatePeriod.months,
                      type: RelativeDateType.last,
                    },
                  },
                }
              : { startDate: [getDefaultStartDate(), endOfDay(new Date()).toISOString()] }),
            keyMetrics: ['open_tickets_granular', 'active_tickets_granular', 'over_sla_granular'],
            analyze: {
              sort: [],
              metrics: ['over_sla_percent_granular', 'over_sla_granular', 'open_tickets_granular'],
              rowDim: 'ticket.assignee_id',
              colDim: 'date',
              page: '0',
            },
          },
        }
      ),
    [filters]
  );

  const renderExportButton = showExportDashboard && (
    <ExportButton
      customTooltipText={isExportingDashboard ? 'Exporting dashboard...' : 'Export dashboard as PDF'}
      disabled={isExportingDashboard}
      loading={isExportingDashboard}
      onClick={exportDashboard}
      showToolbarOnDisabled
    />
  );

  const { SaveViewButtons, SelectedView, setSelectedViewChanged } = useSavedViews({
    viewScreenType: ScreenType.RemediationDashboard,
    filters,
    setFilters,
    defaultFilters,
  });

  const onFilterChange = (field, value) => {
    const next = { ...filters, [field]: value || '' };
    setFilters(next);
    setSelectedViewChanged(true);
  };

  const onClearFiltersHandler = () => {
    const newFilters = {
      ...Object.keys(filters).reduce((acc, key) => {
        if (isWidgetPropsKey(key)) {
          acc[key] = filters[key];
        }
        return acc;
      }, {}),
    };
    setFilters(newFilters);
  };

  const ticketTypeLabels = { ...entityViewConfig.Ticket.labelsV2, [owner]: 'Assignee' };

  const severitySeries = [
    { name: 'Critical', color: theme.palette.error.main, dataKey: 'CRITICAL', type: 'line' },
    { name: 'High', color: theme.palette.colors.orange[500], dataKey: 'HIGH', type: 'line' },
    { name: 'Medium', color: theme.palette.colors.yellow[500], dataKey: 'MEDIUM', type: 'line' },
    { name: 'Others', color: theme.palette.primary.main, dataKey: 'OTHERS', type: 'line' },
  ];

  const { measurementOptions, selectValueMap } = useRemediationMeasurementOptions();

  const widgetData = useWidgetData();
  const filtersWhere = useBuildWhereClause({
    filters: { ...transformedFilters?.filters, ticketCategory: [entityViewConfig.Ticket.app] },
    extra: ["ticket.state != 'ARCHIVED'"],
    entityTypeId: entityViewConfig.Ticket.entityTypeId,
  });

  const dateObject = featureFlags[FeatureFlags.NewDynamicRange]
    ? getStringConditionFromDatePicker(transformedFilters.props.dateObject, featureFlags[FeatureFlags.NewDynamicRange]).dateCondition
    : {};
  const { value } = featureFlags[FeatureFlags.NewDynamicRange]
    ? getDateFromFilter(dateObject as DateCondition, featureFlags[FeatureFlags.NewDynamicRange])
    : { value: { from: new Date(), to: new Date() } };

  const startDate = featureFlags[FeatureFlags.NewDynamicRange]
    ? new Date(value.from)
    : transformedFilters.props.startDate?.[0]
      ? new Date(transformedFilters.props.startDate?.[0])
      : new Date();
  const endDate = featureFlags[FeatureFlags.NewDynamicRange]
    ? value.to
      ? new Date(value.to)
      : new Date()
    : transformedFilters.props.startDate?.[1]
      ? new Date(transformedFilters.props.startDate?.[1])
      : new Date();
  const datePickerValue = { from: startDate, to: endDate };
  const dateGap = transformedFilters.props.dateGap?.[0];
  const utcStartDate = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000);
  const utcEndDate = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000);

  const timeArgs = `'${utcStartDate.toISOString()}', '${utcEndDate.toISOString()}', '${dateGap}'`;
  const dateFormat = DateFormats[dateGap];
  const keyMetricsSql = widgetData.keyMetricsOverTime.sql(
    transformedFilters?.props?.keyMetrics?.map(m => selectValueMap[m]) || [],
    filtersWhere,
    timeArgs
  );

  const overTimeTableMetrics = transformedFilters?.props?.analyze.metrics?.map(m => selectValueMap[m]);

  const bucketsCountSql = widgetData.analyzeRemediationWork.buckets(timeArgs);
  const { data: bucketsCount } = useQuerySql({
    key: bucketsCountSql,
    sql: bucketsCountSql,
  });

  const sqlProps = {
    where: filtersWhere,
    timeArgs,
    metrics: overTimeTableMetrics,
    rowDim: transformedFilters?.props?.analyze?.rowDim,
    explodeFields: explodeFields(),
    page: transformedFilters?.props?.analyze?.page || 0,
    buckets: bucketsCount?.length || 0,
  };

  const overTimeTableSQL = widgetData.analyzeRemediationWork.sql(sqlProps);

  const exportOverTimeTableSql = widgetData.analyzeRemediationWork.sql({
    ...sqlProps,
    isExport: true,
  });

  return (
    <Box sx={{ ...flex.col, overflow: 'auto', width: '100%', gap: 2 }}>
      <Typography variant="h3" sx={{ mb: 1 }}>
        Remediation History
      </Typography>
      <Box sx={{ ...flex.itemsCenter, gap: 2 }}>
        {SelectedView}
        <Box sx={{ flexGrow: 1 }} />
        {SaveViewButtons}
      </Box>
      <Box sx={{ ...flex.justifyBetweenStart, pr: 1 }}>
        <TicketFilters
          activeProjName={entityViewConfig.Ticket.projectionName}
          filters={{ ...transformedFilters?.filters }}
          setFilters={setFilters}
          onClearFilters={onClearFiltersHandler}
          updateFilters={onFilterChange}
          currentTypeLabels={ticketTypeLabels}
        />

        <Box sx={{ ...flex.itemsCenter, gap: 1 }}>
          {renderExportButton}
          <ToggleButtonGroup
            size="small"
            color="white"
            onChange={e => onFilterChange('dateGap', [(e.target as HTMLInputElement).value as DateGap])}
            value={dateGap}>
            <ToggleButton value={DateGap.Day} disabled={dateGap === DateGap.Day}>
              Day
            </ToggleButton>
            <ToggleButton value={DateGap.Week} disabled={dateGap === DateGap.Week}>
              Week
            </ToggleButton>
            <ToggleButton value={DateGap.Month} disabled={dateGap === DateGap.Month}>
              Month
            </ToggleButton>
          </ToggleButtonGroup>
          <AvDateRangePicker
            showDropdownIcon
            inputSx={{ width: '500px' }}
            icon={<DatePickerStartAdornment tooltipText="Historical Data" icon={<HistoryData />} />}
            value={datePickerValue}
            activePresetValue={featureFlags[FeatureFlags.NewDynamicRange] ? transformedFilters.props.dateObject?.preset : undefined}
            onChange={timeRange => {
              if (featureFlags[FeatureFlags.NewDynamicRange]) {
                onFilterChange('dateObject', isNullOrUndefined(timeRange.value) ? [] : [encodeURIComponent(JSON.stringify(timeRange))]);
              } else if (timeRange.value?.to && timeRange.value?.from) {
                onFilterChange('startDate', [timeRange.value.from.toISOString(), timeRange.value.to.toISOString()]);
              }
            }}
            isEditableInput={false}
            showRelativeOptions={featureFlags[FeatureFlags.NewDynamicRange]}
            isRange
            allowFutureDates={false}
            showOptions={!featureFlags[FeatureFlags.NewDynamicRange]}
          />
        </Box>
      </Box>
      <Box sx={{ overflow: 'auto', position: 'relative' }}>
        <ReactGridLayout
          className="layout"
          margin={[12, 12]}
          layout={[
            { i: 'a', x: 0, y: 0, w: 12, h: 2, static: true },
            { i: 'b', x: 0, y: 2, w: 12, h: 5, static: true },
            { i: 'c', x: 0, y: 7, w: 6, h: 5, static: true },
            { i: 'd', x: 6, y: 7, w: 6, h: 5, static: true },
            { i: 'e', x: 0, y: 12, w: 12, h: 10, static: true },
          ]}
          cols={12}
          rowHeight={50}>
          <div style={{ ...flex.row }} key="a">
            <Widget
              queryOptions={{ placeholderData: keepPreviousData }}
              background="transparent"
              sql={`${widgetData.severityStatusBars.sql(filtersWhere)}`}>
              <SeverityStatusBars />
            </Widget>
          </div>
          <div style={{ ...flex.row }} key="b">
            <Widget
              height="100%"
              dateFormat={dateFormat}
              historicalDataProps={{ hideText: false }}
              title={widgetData.keyMetricsOverTime.title}
              queryOptions={{ placeholderData: keepPreviousData }}
              sql={keyMetricsSql}>
              <KeyMetricsOverTime
                limit={metricLimit}
                metrics={transformedFilters?.props?.keyMetrics}
                updateFilters={onFilterChange}
                metricOptions={measurementOptions}
                disabledTooltipText={`Selection is limited to ${metricLimit} metrics`}
              />
            </Widget>
          </div>
          <div style={{ ...flex.row }} key="c">
            <Widget
              height="100%"
              dateFormat={dateFormat}
              historicalDataProps={{ hideText: false }}
              {...widgetData.activeTicketsProgressOverTime}
              sql={widgetData.activeTicketsProgressOverTime.sql(filtersWhere, timeArgs)}
              queryOptions={{ placeholderData: keepPreviousData }}>
              <LineWidget series={severitySeries} isDate />
            </Widget>
          </div>
          <div style={{ ...flex.row }} key="d">
            <Widget {...widgetData.activeTicketsProgress} sql={UIConfig?.tickets ? widgetData.activeTicketsProgress.sql(filtersWhere) : ''}>
              <ActiveTicketsProgress />
            </Widget>
          </div>
          <div style={{ ...flex.row }} key="e">
            <Box
              sx={{
                ...flex.col,
                width: '100%',
                height: 'auto',
                overflow: 'auto',
                gap: 1,
                padding: 3,
                background: theme => theme.palette.white.main,
                position: 'relative',
              }}>
              <OverTimeTableHeader
                {...transformedFilters?.props?.analyze}
                updateFilters={onFilterChange}
                title={widgetData.analyzeRemediationWork.title}
                metricOptions={measurementOptions}
                sql={exportOverTimeTableSql}
              />
              <Widget
                width="100%"
                dateFormat={dateFormat}
                sx={{ p: 0, position: 'unset' }}
                historicalDataProps={{ hideText: false }}
                queryOptions={{ placeholderData: keepPreviousData }}
                title={widgetData.analyzeRemediationWork.title}
                hideTitle
                showTotalRows
                sql={[overTimeTableSQL, widgetData.analyzeRemediationWork.totals(filtersWhere, timeArgs, overTimeTableMetrics)]}>
                <OverTimeTable {...transformedFilters?.props?.analyze} metricOptions={measurementOptions} updateFilters={onFilterChange} />
              </Widget>
            </Box>
          </div>
        </ReactGridLayout>
      </Box>
    </Box>
  );
}

export default DashboardRemediationPage;
