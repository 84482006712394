import { useSnackbar } from 'notistack';
import { getNetworkErrorCode } from '../utils/Utils';

const checkTimeout = status => [502, 503, 504].includes(status);
export default function useHandleError(shouldShowError?) {
  const { enqueueSnackbar } = useSnackbar();
  return (error?, others?) => {
    const status = getNetworkErrorCode(error);
    const errMessage =
      error?.message || (Array.isArray(error) && error.map(e => e?.message || '').join(', ')) || (typeof error === 'string' && error);
    if (['signal is aborted without reason', 'The operation was aborted.'].includes(errMessage?.toString()?.trim())) {
      return;
    }
    const isValidationError =
      [400, 429].includes(status) ||
      error?.[0]?.extensions?.classification === 'BAD_REQUEST' ||
      error?.[0]?.extensions?.classification === 'ValidationError';
    console.error({ status: isValidationError ? '[validation error]' : undefined, error, ...others });
    enqueueSnackbar(
      status === 403
        ? 'Access Denied'
        : checkTimeout(status)
          ? 'Request Timed out'
          : shouldShowError
            ? errMessage
            : 'Something went wrong, Our engineers have been notified',
      {
        variant: status === 429 ? 'warning' : 'error',
      }
    );
  };
}
